import React, { ChangeEvent, ReactText, forwardRef } from 'react'
import styled from '@emotion/styled'

import useId from '../hooks/useId'
import { body2, body3, body4 } from '../styles/typography'
import { screenReaderText, focusRing, inputReset } from '../styles/mixin'

interface Props {
  className?: string
  type?: 'text' | 'email' | 'password' | 'number' | 'hidden'
  label: string
  name?: string
  value?: ReactText
  role?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  autoComplete?: string
  hideLabel?: boolean
  assistiveText?: string
  assistiveEl?: React.ReactNode
  leftEl?: React.ReactNode
  rightEl?: React.ReactNode
  error?: boolean
  errorMessage?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  testId?: string
  readOnly?: boolean
}

const INPUT_HEIGHT = '48px'
const ERROR_MESSAGE_LINE_HEIGHT = '20px'
const ERROR_MESSAGE_PADDING_BOTTOM = '8px'
const LABEL_HEIGHT = '20px'

const Wrapper = styled.div`
  width: 100%;
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${LABEL_HEIGHT};
  margin-bottom: 8px;
`

const Label = styled.label<Pick<Props, 'hideLabel'>>`
  ${body3}
  ${({ hideLabel }) => hideLabel && screenReaderText}
  color: ${({ theme }) => theme.colors.darkgray80};
  line-height: ${LABEL_HEIGHT};
`

const AssistiveEl = styled.div`
  width: ${LABEL_HEIGHT};
  height: ${LABEL_HEIGHT};
  color: ${({ theme }) => theme.colors.lightgray80};
`

const AssistiveText = styled.div`
  ${body4}
  color: ${({ theme }) => theme.colors.darkgray20};
`

const InputWrapper = styled.div`
  position: relative;
  min-height: calc(${INPUT_HEIGHT} + ${ERROR_MESSAGE_LINE_HEIGHT} + ${ERROR_MESSAGE_PADDING_BOTTOM});
`

const StyledInput = styled.input<Pick<Props, 'leftEl' | 'rightEl' | 'error'>>`
  /* TODO: 서비스에 디자인시스템 모두 적용되면, inputReset CSSReset으로 옮기기 & 스냅샷 업데이트 */
  ${inputReset}
  ${body2}
  width: 100%;
  height: ${INPUT_HEIGHT};
  padding-top: 12px;
  padding-right: ${({ rightEl }) => (rightEl ? '48px' : '16px')};
  padding-bottom: 12px;
  padding-left: ${({ leftEl }) => (leftEl ? '48px' : '16px')};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.lightgray60};
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.white100};
  color: ${({ theme }) => theme.colors.darkgray80};

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.darkgray20};
  }

  &:disabled {
    border-color: ${({ theme }) => theme.colors.lightgray80};
    background: ${({ theme }) => theme.colors.lightgray20};
    color: ${({ theme }) => theme.colors.darkgray20};
    opacity: ${({ theme }) => theme.opacity.disabled};
    cursor: not-allowed;
  }

  &:focus {
    ${focusRing}
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ error, theme }) =>
    error &&
    `
      border-color: ${theme.colors.red20};
      background-color:  ${theme.colors.errorBackground};
    `}
`

const LeftEl = styled.div`
  position: absolute;
  top: 12px;
  left: 16px;
  display: flex;
  align-items: center;
  height: 22px;
  color: ${({ theme }) => theme.colors.lightgray80};
`

const RightEl = styled.div`
  position: absolute;
  top: 12px;
  right: 16px;
  display: flex;
  align-items: center;
  height: 22px;
  color: ${({ theme }) => theme.colors.lightgray80};
`

const P = styled.p`
  ${body3}
  width: 100%;
  padding-bottom: ${ERROR_MESSAGE_PADDING_BOTTOM};
  color: ${({ theme }) => theme.colors.red20};
  line-height: ${ERROR_MESSAGE_LINE_HEIGHT};
`

const Input = forwardRef(
  (
    {
      className,
      type = 'text',
      label,
      name,
      value,
      role,
      placeholder,
      required,
      disabled = false,
      autoComplete = 'on',
      hideLabel = false,
      assistiveText,
      assistiveEl,
      leftEl,
      rightEl,
      error = false,
      errorMessage,
      onChange,
      onBlur,
      testId,
      readOnly,
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const id = useId('Input')

    if (type === 'hidden') {
      return <input id={id} name={name ?? label} type={type} value={value} />
    }

    return (
      <Wrapper className={className}>
        <LabelWrapper>
          <Label hideLabel={hideLabel} htmlFor={id}>
            {label}
          </Label>
          {assistiveEl && <AssistiveEl>{assistiveEl}</AssistiveEl>}
          {assistiveText && <AssistiveText>{assistiveText}</AssistiveText>}
        </LabelWrapper>
        <InputWrapper>
          {leftEl && <LeftEl>{leftEl}</LeftEl>}
          <StyledInput
            ref={ref}
            id={id}
            name={name ?? label}
            type={type}
            role={role}
            placeholder={placeholder}
            value={value}
            autoComplete={autoComplete}
            aria-invalid={error}
            disabled={disabled}
            required={required}
            error={error}
            leftEl={leftEl}
            rightEl={rightEl}
            onChange={onChange}
            onBlur={onBlur}
            data-testid={testId}
            readOnly={readOnly}
          />
          {rightEl && <RightEl>{rightEl}</RightEl>}
          {error && errorMessage && <P>{errorMessage}</P>}
        </InputWrapper>
      </Wrapper>
    )
  }
)

export default Input
