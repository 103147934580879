import * as React from 'react'

function SvgSocialAppleSymbol(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.39 7.077c.797 0 1.808-.54 2.403-1.258.539-.663.92-1.561.92-2.47 0-.124-.01-.248-.033-.349-.876.034-1.954.584-2.594 1.336-.494.573-.966 1.483-.966 2.393 0 .134.023.28.034.325.056.011.146.023.236.023zm-2.797 13.6c1.09 0 1.572-.719 2.931-.719 1.37 0 1.685.696 2.887.696 1.201 0 1.999-1.09 2.751-2.167.831-1.247 1.19-2.46 1.202-2.516-.068-.022-2.359-.954-2.359-3.582 0-2.269 1.797-3.28 1.898-3.358-1.179-1.719-2.987-1.763-3.492-1.763-1.337 0-2.415.808-3.111.808-.73 0-1.707-.764-2.864-.764C7.235 7.313 5 9.143 5 12.569c0 2.145.831 4.403 1.853 5.874.865 1.224 1.64 2.235 2.74 2.235z"
      />
    </svg>
  )
}

export default SvgSocialAppleSymbol
