import { css, Theme } from '@emotion/react'

import { mobile } from './responsive'

const defaultStyle = css`
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`

export const display1 = css`
  ${defaultStyle}
  font-weight: bold;
  font-size: 60px;
  ${mobile} {
    font-size: 40px;
  }
`

export const display2 = css`
  ${defaultStyle}
  font-weight: bold;
  font-size: 48px;
  ${mobile} {
    font-size: 32px;
  }
`

export const title1 = css`
  ${defaultStyle}
  font-size: 32px;
  ${mobile} {
    font-size: 24px;
  }
`

export const title2 = css`
  ${defaultStyle}
  font-size: 24px;
  ${mobile} {
    font-size: 18px;
  }
`

export const title3 = css`
  ${defaultStyle}
  font-size: 18px;
  ${mobile} {
    font-size: 16px;
  }
`

export const body1 = css`
  ${defaultStyle}
  font-size: 20px;
`

export const body2 = css`
  ${defaultStyle}
  font-size: 16px;
`

export const body3 = css`
  ${defaultStyle}
  font-size: 14px;
`

export const body4 = css`
  ${defaultStyle}
  font-size: 12px;
`

export const badge = css`
  ${defaultStyle}
  font-size: 10px;
`

export const buttonTextLarge = ({ theme }: { theme: Theme }) => css`
  ${defaultStyle}
  font-weight: ${theme.fontWeights.bold};
  font-size: 16px;
`

export const buttonTextSmall = ({ theme }: { theme: Theme }) => css`
  ${defaultStyle}
  font-weight: ${theme.fontWeights.bold};
  font-size: 14px;
`

export default {
  display1,
  display2,
  title1,
  title2,
  title3,
  body1,
  body2,
  body3,
  body4,
  badge,
}
