import * as React from 'react'

function SvgFilterFunnel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.32 9.014a.522.522 0 000-1.042H8.754l-.9-1.384H9.99a.522.522 0 000-1.042H7.176l-.977-1.504h11.572l-4.37 6.722h-2.833L9.43 9.014h1.89zm1.697 2.793v6.095c0 .404-.33 1.02-.665 1.244l-1.392.927v-8.267h2.057zm5.865-8.368C18.73 3.156 18.396 3 17.946 3H6.024c-.45 0-.783.156-.936.44-.154.283-.104.647.141 1.024l4.689 7.214v8.807c0 .54.27.89.689.89.184 0 .388-.071.59-.206l1.734-1.155c.622-.416 1.129-1.363 1.129-2.112v-6.236l4.68-7.202c.246-.377.296-.741.142-1.025z"
      />
    </svg>
  )
}

export default SvgFilterFunnel
