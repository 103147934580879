import React, { ReactNode, useRef, useState, MouseEvent } from 'react'
import styled from '@emotion/styled'

import { body3, title3 } from '../styles/typography'
import useId from '../hooks/useId'
import useEventListener from '../hooks/useEventListener'

type Position = 'left' | 'center' | 'right'

interface Props {
  className?: string
  title?: string
  position?: Position
  /**
   * 문자열이나 커스텀 컴포넌트를 직접 전달한다.
   */
  description: ReactNode | string
  /**
   * 툴팁을 트리거할 컴포넌트로 버튼으로 한번 랩핑되어 렌더링된다.
   */
  children: ReactNode
  zIndex?: number
  testId?: string
}

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`

const Button = styled.button`
  display: block;
`

const TooltipWrapper = styled.div<{ position?: Position; zIndex?: number }>`
  position: absolute;
  right: ${({ position }) => (position === 'right' ? '0px' : '')};
  left: ${({ position }) => (position === 'left' ? '0px' : position === 'center' ? 'calc(50% - 140px)' : '')};
  z-index: ${({ zIndex }) => zIndex ?? 100};
  width: 280px;
  margin-top: 6px;
  padding: 16px;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.darkgray60};
  color: ${({ theme }) => theme.colors.odxWhite};
  text-align: left;

  &::before {
    content: '';
    position: absolute;
    top: -6px;
    right: ${({ position }) => (position === 'right' ? '8px' : '')};
    left: ${({ position }) => (position === 'left' ? '8px' : position === 'center' ? '136px' : '')};
    width: 0;
    height: 0;
    border-right: 4px solid transparent;
    border-bottom: 6px solid ${({ theme }) => theme.colors.darkgray60};
    border-left: 4px solid transparent;
  }

  > * {
    pointer-events: none;
  }
`

const P = styled.p`
  ${title3}
  margin-bottom: 8px;
  font-weight: 700;
`

const DescriptionWrapper = styled.div`
  ${body3}
`

export default function Tooltip({ className, title, position = 'left', zIndex, description, testId, children }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const tooltipId = useId('Tooltip')
  const $triggerRef = useRef<HTMLButtonElement>(null)
  const $tooltipRef = useRef<HTMLDivElement>(null)

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsOpen(prev => !prev)
  }

  // NOTE: Close the tooltip when click outside
  useEventListener([
    {
      eventName: 'click',
      callback: e => {
        e.stopPropagation()
        if (e.target !== $triggerRef.current && !$tooltipRef.current?.contains(e.target)) {
          setIsOpen(false)
        }
      },
    },
  ])

  return (
    <Wrapper>
      <Button onClick={handleClick} aria-describedby={tooltipId} ref={$triggerRef}>
        {children}
      </Button>
      <TooltipWrapper
        ref={$tooltipRef}
        className={className}
        id={tooltipId}
        aria-hidden={!isOpen}
        hidden={!isOpen}
        position={position}
        zIndex={zIndex}
        data-testid={testId}
      >
        {title && <P>{title}</P>}
        <DescriptionWrapper>{description}</DescriptionWrapper>
      </TooltipWrapper>
    </Wrapper>
  )
}
