import * as React from 'react'

function SvgArrowMiniDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15a.474.474 0 01-.354-.163l-3.5-3.889a.6.6 0 010-.785.465.465 0 01.708 0L12 13.659l3.146-3.496a.465.465 0 01.707 0 .6.6 0 010 .785l-3.5 3.89A.474.474 0 0112 15z"
      />
    </svg>
  )
}

export default SvgArrowMiniDown
