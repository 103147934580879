import React from 'react'
import styled from '@emotion/styled'

import { badge, body4 } from '../styles/typography'

import Icon from './Icon'

import type { IconType } from './Icon'

interface Props {
  className?: string
  label: string
  variant?: 'solid' | 'solidEdge' | 'outline'
  /**
   * 사용 가능한 컬러: primary, secondary, red40, lightgray80, darkgray20, darkgray40, darkgray60
   */
  colorVariant?: 'primary' | 'secondary' | 'red40' | 'lightgray80' | 'darkgray20' | 'darkgray40' | 'darkgray60'
  icon?: IconType
  fontWeight?: 'normal' | 'bold'
  testId?: string
}

const badgePadding = {
  solid: '4px',
  solidEdge: '8px',
  outline: '6px',
}

const Span = styled.span<Required<Pick<Props, 'variant' | 'colorVariant'>> & Pick<Props, 'fontWeight' | 'icon'>>`
  ${({ variant }) => (variant === 'solidEdge' ? body4 : badge)}
  display: flex;
  align-items: center;
  height: ${({ variant }) => (variant === 'solid' ? '18px' : '20px')};
  padding-right: ${({ variant }) => badgePadding[variant]};
  padding-left: ${({ variant, icon }) => (icon ? '6px' : badgePadding[variant])};
  border: ${({ theme, variant, colorVariant }) =>
    variant === 'outline' ? `1px solid ${theme.colors[colorVariant]}` : 0};
  border-top-left-radius: ${({ variant }) => (variant === 'outline' ? '10px' : '4px')};
  border-top-right-radius: ${({ variant }) => (variant === 'outline' ? '10px' : variant === 'solid' ? '4px' : 0)};
  border-bottom-right-radius: ${({ variant }) => (variant === 'outline' ? '10px' : '4px')};
  border-bottom-left-radius: ${({ variant }) => (variant === 'outline' ? '10px' : variant === 'solid' ? '4px' : 0)};
  background-color: ${({ theme, variant, colorVariant }) =>
    variant === 'outline' ? 'transparent' : theme.colors[colorVariant]};
  color: ${({ theme, variant, colorVariant }) =>
    variant === 'outline' ? theme.colors[colorVariant] : theme.colors.odxWhite};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ variant }) => (variant === 'solidEdge' ? '20px' : '18px')};
`

const StyledIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: 2px;
  line-height: 18px;
`

export default function Badge({
  className,
  label,
  variant = 'solid',
  colorVariant = 'primary',
  fontWeight = 'normal',
  icon,
  testId,
}: Props) {
  return (
    <Span
      className={className}
      variant={variant}
      colorVariant={colorVariant}
      fontWeight={fontWeight}
      icon={icon}
      data-testid={testId}
    >
      {icon && <StyledIcon name={icon} />}
      {label}
    </Span>
  )
}
