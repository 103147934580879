import * as React from 'react'

function SvgStar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm4.147-6.75c.227 1.38-.819 2.17-2.008 1.518L12 16.598l-2.139 1.17c-1.19.652-2.235-.14-2.008-1.519l.409-2.48-1.73-1.755c-.964-.978-.563-2.257.766-2.458l2.392-.362 1.069-2.256c.597-1.26 1.891-1.246 2.487.012l1.064 2.244 2.392.362c1.331.202 1.729 1.481.767 2.458l-1.73 1.756.408 2.48z"
      />
    </svg>
  )
}

export default SvgStar
