import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import useId from '../hooks/useId'

import { body3 } from '../styles/typography'

import Icon from './Icon'
import Text from './Text'
import Heading from './Heading'

import type { HeadingTag } from './Heading'

interface Item {
  title: string
  contents: string
}

interface Props {
  /**
   * `{ title: string; contents: string }[]` 형태의 Accordion 이루는 아이템 배열이다. 각각의 아이템은 title, contents를 포함하고 있어야한다.
   */
  items: Item[]
  /**
   * Accordion의 헤딩 레벨을 나타낸다.(h1 - h6)
   */
  heading: HeadingTag
  className?: string
  allowMultipleOpen?: boolean
  /**
   * Accordion의 위아래 패딩을 없앨때 사용한다.
   */
  noPadding?: boolean
  prefix?: string
  testId?: string
}

const Ul = styled.ul<Pick<Props, 'noPadding'>>`
  width: 100%;
  padding: ${({ noPadding }) => (noPadding ? 0 : '16px')};
  border: 1px solid ${({ noPadding }) => (noPadding ? 'initial' : 'transparent')};
  border-radius: ${({ noPadding }) => (noPadding ? 0 : '8px')};
  background-color: ${({ theme }) => theme.colors.odxWhite};
  list-style: none;
`

const Button = styled.button<{ isOpen: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightgray40};
  color: ${({ theme }) => theme.colors.darkgray20};
  text-align: left;
  cursor: pointer;

  &:focus {
    color: ${({ theme }) => theme.colors.darkgray80};

    svg {
      color: ${({ theme }) => theme.colors.darkgray20};
    }
  }

  li:last-child & {
    border-bottom: ${({ isOpen }) => !isOpen && 'initial'};
  }
`

const StyledText = styled(Text)`
  display: flex;
`

const StyledSpan = styled.span`
  margin-right: 8px;
`

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  margin-left: 16px;
`

const Div = styled.div<{ isOpen: boolean }>`
  ${body3}

  overflow: hidden;
  height: ${({ isOpen }) => (isOpen ? 'initial' : 0)};
  padding: ${({ isOpen }) => (isOpen ? '16px' : '0 16px')};
  background-color: ${({ theme }) => theme.colors.lightgray20};
  color: ${({ theme }) => theme.colors.darkgray80};
  transition: all 0.3s ease;
`

export default function Accordion({
  items,
  heading,
  className,
  allowMultipleOpen = false,
  noPadding = false,
  prefix,
  testId,
}: Props) {
  const [activeItemIndices, setActiveItemIndices] = useState<number[]>([])
  const accordionId = useId('Accordion')

  const toggleActiveItem = (index: number) => {
    activeItemIndices.includes(index)
      ? setActiveItemIndices(activeItemIndices.filter(itemIndex => itemIndex !== index))
      : setActiveItemIndices(allowMultipleOpen ? [...activeItemIndices, index] : [index])
  }

  const isOpen = (index: number) => activeItemIndices.includes(index)

  useEffect(() => {
    setActiveItemIndices([])
  }, [items])

  return (
    <Ul className={className} data-testid={testId} noPadding={noPadding}>
      {items.map(({ title, contents }, index) => (
        <li key={`${title}-${index}`}>
          <Heading as={heading}>
            <Button
              aria-controls={`${accordionId}__content`}
              aria-expanded={isOpen(index)}
              id={`${accordionId}__button`}
              onClick={() => toggleActiveItem(index)}
              isOpen={isOpen(index)}
            >
              <StyledText size="body2" as="div">
                {prefix && <StyledSpan>{prefix}</StyledSpan>}
                <div>{title}</div>
              </StyledText>
              <StyledIcon name={isOpen(index) ? 'ArrowMiniUp' : 'ArrowMiniDown'} />
            </Button>
          </Heading>
          <Div
            id={`${accordionId}__content`}
            isOpen={isOpen(index)}
            aria-hidden={!isOpen(index)}
            aria-labelledby={`${accordionId}__button`}
            role="region"
            dangerouslySetInnerHTML={{
              __html: contents,
            }}
          />
        </li>
      ))}
    </Ul>
  )
}
