import React from 'react'
import styled from '@emotion/styled'

import * as Icons from './icons'

const IconSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
} as const

export type IconSize = typeof IconSize[keyof typeof IconSize]
export type IconType = keyof typeof Icons

interface StyledProps {
  /**
   * 아이콘 사이즈를 나타낸다. small:16px, medium: 20px, large: 24px(디폴트)
   */
  size?: IconSize
}

interface SVGProps {
  name: IconType
  className?: string
  /**
   * 아이콘 단독으로 사용할 경우(장식요소가 아닌경우) 스크린리더에 의해 설명할 타이틀을 제공한다.
   * title prop이 주어지면 svg의 aria-hidden 설정은 해지된다.
   */
  title?: string
  testId?: string
}

export interface Props extends StyledProps, SVGProps {}

// Temporary type: cra의  SVGProps 타입에 title이 반영되어 있지 않은 버그
type ODXSvg = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title: string }>

const SVGComponent = ({ name, className, title, testId }: SVGProps) => {
  const SVG: ODXSvg = Icons[name]
  return (
    <SVG
      className={className}
      aria-hidden={title ? undefined : true}
      title={title ?? name}
      role="img"
      data-testid={testId}
    />
  )
}

const StyledIcon = styled(SVGComponent)<StyledProps>`
  /* inline-element(svg)는 text baseline을 따르기 때문에 white-space를 포함하므로 불필요한 height영역을 없애기 위해 block요소로 만든다. */
  display: inline-block;
  flex-shrink: 0;

  /* NOTE: Prevent shrinking when used in flex-box */
  width: ${({ size, theme }) => theme.size.icon[size ?? IconSize.LARGE]};
  height: ${({ size, theme }) => theme.size.icon[size ?? IconSize.LARGE]};

  /* svg가 아이콘(이미지)의 용도로 사용되므로 pointer-events를 제거한다. */
  pointer-events: none;
`

export default function Icon({ name, className, size = IconSize.LARGE, title, testId }: Props) {
  return <StyledIcon className={className} name={name} title={title} size={size} testId={testId} />
}
