import * as React from 'react'

function SvgBell(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zM8.214 9.868v2.027c0 .162-.025.323-.076.476L6.94 15.963c-.131.394.101.72.522.72h9.075c.421 0 .655-.323.521-.72l-1.196-3.593a1.504 1.504 0 01-.077-.475V9.867a3.786 3.786 0 00-3.032-3.71.757.757 0 10-1.51-.074.6.6 0 00.004.074 3.782 3.782 0 00-1.923 1.034 3.777 3.777 0 00-1.11 2.677zm3.868 9.062a1.5 1.5 0 001.5-1.5h-3a1.5 1.5 0 001.5 1.5z"
      />
    </svg>
  )
}

export default SvgBell
