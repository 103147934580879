import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { truncatedText } from '../styles/mixin'
import Typography from '../styles/typography'

interface Props {
  className?: string
  as?: React.ElementType
  size?: 'body1' | 'body2' | 'body3' | 'body4' | 'badge'
  weight?: 'normal' | 'medium' | 'bold'
  lineHeight?: number
  isTruncated?: boolean
  children: React.ReactNode
}

const StyledText = styled.p<Props>`
  ${({ size = 'body1', weight, lineHeight, isTruncated }) => css`
    ${Typography[size]}
    ${weight && `font-weight: ${weight === 'medium' ? 500 : weight};`}
    ${lineHeight && `line-height: ${lineHeight}`}
    ${isTruncated && truncatedText}
  `}
`

export default function Text({ className, as, size = 'body1', weight, isTruncated, lineHeight, children }: Props) {
  return (
    <StyledText
      as={as}
      className={className}
      size={size}
      weight={weight}
      isTruncated={isTruncated}
      lineHeight={lineHeight}
    >
      {children}
    </StyledText>
  )
}
