import * as React from 'react'

function SvgFilterControl(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 4h-7.17a3.001 3.001 0 00-5.66 0H3a1 1 0 000 2h5.17a3.001 3.001 0 005.66 0H21a1 1 0 100-2zM11 7a2 2 0 100-4 2 2 0 000 4zm10 4h-3.17a3.001 3.001 0 00-5.66 0H3a1 1 0 100 2h9.17a3.001 3.001 0 005.66 0H21a1 1 0 100-2zm-6 3a2 2 0 100-4 2 2 0 000 4zm-3.17 4H21a1 1 0 110 2h-9.17a3.001 3.001 0 01-5.66 0H3a1 1 0 110-2h3.17a3.001 3.001 0 015.66 0zM11 19a2 2 0 11-4 0 2 2 0 014 0z"
      />
    </svg>
  )
}

export default SvgFilterControl
