import React from 'react'
import { Global, css } from '@emotion/react'

export const CSSReset = () => (
  <Global
    styles={css`
      /* Document */

      *,
      *::before,
      *::after {
        box-sizing: border-box;
      }

      /* Elements */

      a {
        background-color: transparent;
        color: inherit;
        text-decoration: none;
      }

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
      }

      ol,
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      /* Form */

      button {
        margin: 0;
        padding: 0;
        border: 0;
        background-color: transparent;
        color: inherit;
        line-height: inherit;
        text-transform: none;
      }

      input,
      textarea,
      button {
        /**
         * Form 요소는 디폴트 세팅으로 font inherit 받지 않는다. 따라서 아래 코드를 직접 설정해줘야 한다.
         */
        font-family: inherit;
      }
    `}
  />
)

export default CSSReset
