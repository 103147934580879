import * as React from 'react'

function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.115 15.802a.5.5 0 01-.716 0l-2.995-3.078a.987.987 0 00-1.414 1.377l4.408 4.53a.5.5 0 00.717 0l10.65-10.945a.983.983 0 00-1.41-1.372l-9.24 9.488z"
      />
    </svg>
  )
}

export default SvgCheck
