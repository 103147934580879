import * as React from 'react'

function SvgSocialWechat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.864 8.099a9.072 9.072 0 00-1.114-.076c-4.136 0-7.5 2.81-7.5 6.267 0 .402.05.794.138 1.176a8.49 8.49 0 01-1.82-.454L3.876 16.17l.989-1.982C3.123 13.04 2 11.27 2 9.276c0-3.46 3.358-6.267 7.5-6.267 3.66 0 6.704 2.192 7.364 5.09zm-3.927-1.33c0-.519-.42-.94-.937-.94a.939.939 0 00-.937.94.939.939 0 101.874 0zm-6.874 0a.939.939 0 101.875 0 .939.939 0 10-1.875 0zm9.687 2.507c3.451 0 6.25 2.245 6.25 5.013 0 1.604-.955 3.015-2.415 3.932l1.165 2.335-3.369-1.447a7.627 7.627 0 01-1.631.194c-3.451 0-6.25-2.245-6.25-5.014 0-2.768 2.799-5.013 6.25-5.013zm-2.813 3.76a.939.939 0 101.875 0 .939.939 0 10-1.874 0zm3.75 0a.939.939 0 101.875 0 .939.939 0 10-1.875 0z"
      />
    </svg>
  )
}

export default SvgSocialWechat
