import * as React from 'react'

function SvgSocialFacebookCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M13.44 21.897C18.28 21.2 22 17.034 22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.96 3.611 9.077 8.348 9.864v-7.11H7.835v-2.86h2.513v-2.18c0-2.481 1.477-3.85 3.737-3.85 1.082 0 2.215.193 2.215.193v2.435h-1.248c-1.23 0-1.612.764-1.612 1.546v1.856h2.743l-.438 2.98-2.305-.12v7.143z" />
    </svg>
  )
}

export default SvgSocialFacebookCircle
