import * as React from 'react'

function SvgExclamationLine(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M12.026 15.6H12a1.2 1.2 0 00-.026 2.4H12a1.2 1.2 0 00.025-2.4zM13 13a1 1 0 01-2 0V6.999a1 1 0 012 0V13z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.215 2.002a16.136 16.136 0 00-.22-.002C6.573 2 2.12 6.336 2.002 11.784c-.119 5.522 4.26 10.094 9.782 10.214h.004l.216.002c5.422 0 9.876-4.336 9.993-9.784.12-5.522-4.26-10.095-9.782-10.214zM12.005 20l-.178-.002c-4.41-.095-7.92-3.76-7.825-8.17C4.094 7.511 7.682 4 11.996 4l.176.002c4.41.095 7.921 3.76 7.826 8.17-.093 4.317-3.68 7.828-7.994 7.828z"
      />
    </svg>
  )
}

export default SvgExclamationLine
