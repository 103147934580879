export enum KeyCode {
  ESC = 27,
  ENTER = 13,
  SPACEBAR = 32,
  ARROW_LEFT = 37,
  ARROW_RIGHT = 39,
  ARROW_UP = 38,
  ARROW_DOWN = 40,
  TAB = 9,
}
