import React, { ReactNode } from 'react'

import { ButtonVariant, ButtonSize, StyledButton, IconPosition, Span, StyledIcon } from './Button'
import { IconType } from './Icon'

interface Props<C extends React.ElementType> {
  className?: string
  component?: C

  title?: string
  variant?: ButtonVariant
  size?: ButtonSize
  fullWidth?: boolean
  /**
   LinkButton이 텍스트처럼 쓰여져야 할 경우,
   noPadding 옵션을 주면 padding이 제거되고 `height: auto`로 설정되어 함께 있는 텍스트의 플로우에 맞춰집니다.
   */
  noPadding?: boolean

  icon?: IconType
  iconPosition?: IconPosition

  href?: string
  newTab?: boolean
  children?: ReactNode

  testId?: string
}

type ButtonProps<C extends React.ElementType> = Props<C> & Omit<React.ComponentPropsWithRef<C>, keyof Props<C>>

const baseComponent = 'a'

export default function LinkButton<C extends React.ElementType = typeof baseComponent>({
  className,
  component,
  title,
  variant = 'primary',
  size = 'large',
  fullWidth = false,
  noPadding = false,
  icon,
  iconPosition = 'left',
  href,
  newTab = false,
  children,
  testId,
  ...props
}: ButtonProps<C>) {
  const linkProps = newTab ? { href, target: '_blank', rel: 'noopener noreferrer' } : { href }

  return (
    <StyledButton
      as={component ?? baseComponent}
      className={className}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      iconPosition={iconPosition}
      noPadding={noPadding}
      data-testid={testId}
      {...linkProps}
      {...props}
    >
      {icon && (
        <StyledIcon
          name={icon}
          title={variant === 'icon' ? title || icon : undefined}
          size={size === 'small' ? 'medium' : 'large'}
          variant={variant}
          iconPosition={iconPosition}
        />
      )}
      {variant !== 'icon' && <Span size={size}>{children || title}</Span>}
    </StyledButton>
  )
}
