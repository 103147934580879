import * as React from 'react'

function SvgExclamationFill(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.974 18a1.2 1.2 0 11.053-2.4 1.2 1.2 0 01-.053 2.4zM11 7a1 1 0 012 0v6a1 1 0 11-2 0V7zm1.216-4.998C6.694 1.883 2.121 6.263 2.002 11.784c-.119 5.522 4.261 10.095 9.782 10.214 5.522.12 10.094-4.26 10.213-9.782.12-5.522-4.26-10.095-9.78-10.214z"
      />
    </svg>
  )
}

export default SvgExclamationFill
