import React from 'react'
import { Global, css } from '@emotion/react'

export const GlobalStyle = () => (
  <Global
    styles={theme => css`
      body {
        font-family: ${theme.fontFamily};
      }

      button {
        cursor: pointer;
      }

      button:focus,
      a:focus {
        outline: 2px solid ${theme.colors.blue20};
      }
    `}
  />
)

export default GlobalStyle
