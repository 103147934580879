import colors from './colors'

const ODXTheme = {
  odc: {
    name: 'odc',
    colors: {
      ...colors.common,
      primary: colors.odc.pink40,
      secondary: colors.odc.purple40,
    },
    size: {
      icon: {
        small: '16px',
        medium: '20px',
        large: '24px',
      },
    },
    opacity: {
      disabled: 0.4,
    },
    fontFamily:
      '"Noto Sans SC", "Noto Sans", Tahoma, Helvetica, Arial, "Microsoft Yahei", 微软雅黑, STXihei, 华文细黑, sans-serif',
    fontWeights: {
      normal: 400,
      medium: 500,
      bold: 500, // odc의 경우 중국어 특성상 700을 쓰면 가독성이 떨어져 medium과 동일한 500으로 맞춘다.
    },
    button: {
      primary: {
        text: colors.common.odxWhite,
        textHover: colors.common.odxWhite,
        textDisabled: colors.common.darkgray20,
        background: colors.odc.pink40,
        backgroundHover: colors.odc.pink20,
        backgroundDisabled: colors.common.lightgray60,
      },
      secondary: {
        text: colors.common.darkgray20,
        textHover: colors.common.darkgray80,
        textDisabled: colors.common.darkgray20,
        background: colors.common.transparent,
        backgroundHover: colors.common.white100,
        backgroundDisabled: colors.common.transparent,
        border: colors.common.lightgray80,
        borderHover: colors.common.darkgray20,
        borderDisabled: colors.common.lightgray80,
      },
      error: {
        text: colors.common.red40,
        textHover: colors.common.red40,
        textDisabled: colors.common.darkgray20,
        background: colors.common.transparent,
        backgroundHover: colors.common.white100,
        backgroundDisabled: colors.common.transparent,
        border: colors.common.red40,
        borderHover: colors.common.red40,
        borderDisabled: colors.common.lightgray80,
      },
      ghostPrimary: {
        text: colors.common.blue20,
        textHover: colors.common.blue20,
        textDisabled: colors.common.blue20,
        background: colors.common.transparent,
        backgroundHover: colors.common.transparent,
        backgroundDisabled: colors.common.transparent,
      },
      ghostSecondary: {
        text: colors.common.darkgray20,
        textHover: colors.common.lightgray80,
        textDisabled: colors.common.darkgray20,
        background: colors.common.transparent,
        backgroundHover: colors.common.transparent,
        backgroundDisabled: colors.common.transparent,
      },
      icon: {
        text: colors.common.darkgray20,
        textHover: colors.common.lightgray80,
        textDisabled: colors.common.darkgray20,
        background: colors.common.transparent,
        backgroundHover: colors.common.transparent,
        backgroundDisabled: colors.common.transparent,
      },
      subscribe: {
        text: colors.common.odxWhite,
        textHover: colors.common.odxWhite,
        textDisabled: colors.common.darkgray20,
        background: colors.odc.pink,
        backgroundHover: colors.odc.purple40,
        backgroundDisabled: colors.common.lightgray60,
      },
    },
  },
  odl: {
    name: 'odl',
    colors: {
      ...colors.common,
      primary: colors.odl.orange40,
      secondary: colors.odl.secOrange40,
    },
    size: {
      icon: {
        small: '16px',
        medium: '20px',
        large: '24px',
      },
    },
    opacity: {
      disabled: 0.4,
    },
    fontFamily: '"Noto Sans", Tahoma, Helvetica, Arial, sans-serif',
    fontWeights: {
      normal: 400,
      medium: 500,
      bold: 700,
    },
    button: {
      primary: {
        text: colors.common.odxWhite,
        textHover: colors.common.odxWhite,
        textDisabled: colors.common.darkgray20,
        background: colors.odl.orange40,
        backgroundHover: colors.odl.orange20,
        backgroundDisabled: colors.common.lightgray60,
      },
      secondary: {
        text: colors.common.darkgray20,
        textHover: colors.common.darkgray80,
        textDisabled: colors.common.darkgray20,
        background: colors.common.transparent,
        backgroundHover: colors.common.white100,
        backgroundDisabled: colors.common.transparent,
        border: colors.common.lightgray80,
        borderHover: colors.common.darkgray20,
        borderDisabled: colors.common.lightgray80,
      },
      error: {
        text: colors.common.red40,
        textHover: colors.common.red40,
        textDisabled: colors.common.darkgray20,
        background: colors.common.transparent,
        backgroundHover: colors.common.white100,
        backgroundDisabled: colors.common.transparent,
        border: colors.common.red40,
        borderHover: colors.common.red40,
        borderDisabled: colors.common.lightgray80,
      },
      ghostPrimary: {
        text: colors.common.blue20,
        textHover: colors.common.blue20,
        textDisabled: colors.common.blue20,
        background: colors.common.transparent,
        backgroundHover: colors.common.transparent,
        backgroundDisabled: colors.common.transparent,
      },
      ghostSecondary: {
        text: colors.common.darkgray20,
        textHover: colors.common.lightgray80,
        textDisabled: colors.common.darkgray20,
        background: colors.common.transparent,
        backgroundHover: colors.common.transparent,
        backgroundDisabled: colors.common.transparent,
      },
      icon: {
        text: colors.common.darkgray20,
        textHover: colors.common.lightgray80,
        textDisabled: colors.common.darkgray20,
        background: colors.common.transparent,
        backgroundHover: colors.common.transparent,
        backgroundDisabled: colors.common.transparent,
      },
      subscribe: {
        text: colors.common.odxWhite,
        textHover: colors.common.odxWhite,
        textDisabled: colors.common.darkgray20,
        background: colors.odl.orange,
        backgroundHover: colors.odl.secOrange40,
        backgroundDisabled: colors.common.lightgray60,
      },
    },
  },
  odk: {
    name: 'odk',
    colors: {
      ...colors.common,
      primary: colors.odk.green20,
      secondary: colors.odk.blue20,
    },
    size: {
      icon: {
        small: '16px',
        medium: '20px',
        large: '24px',
      },
    },
    opacity: {
      disabled: 0.4,
    },
    fontFamily: '"Noto Sans KR", sans-serif',
    fontWeights: {
      normal: 400,
      medium: 500,
      bold: 700,
    },
    button: {
      primary: {
        text: colors.common.odxWhite,
        textHover: colors.common.odxWhite,
        textDisabled: colors.common.darkgray20,
        background: colors.odk.green20,
        backgroundHover: colors.odk.green40,
        backgroundDisabled: colors.common.lightgray60,
      },
      secondary: {
        text: colors.common.darkgray20,
        textHover: colors.common.darkgray80,
        textDisabled: colors.common.darkgray20,
        background: colors.common.white100,
        backgroundHover: colors.common.white100,
        backgroundDisabled: colors.common.white100,
        border: colors.common.lightgray80,
        borderHover: colors.common.darkgray20,
        borderDisabled: colors.common.lightgray80,
      },
      error: {
        text: colors.common.red40,
        textHover: colors.common.red40,
        textDisabled: colors.common.darkgray20,
        background: colors.common.transparent,
        backgroundHover: colors.common.white100,
        backgroundDisabled: colors.common.transparent,
        border: colors.common.red40,
        borderHover: colors.common.red40,
        borderDisabled: colors.common.lightgray80,
      },
      ghostPrimary: {
        text: colors.odk.green20,
        textHover: colors.odk.green40,
        textDisabled: colors.odk.green20,
        background: colors.common.transparent,
        backgroundHover: colors.common.transparent,
        backgroundDisabled: colors.common.transparent,
      },
      ghostSecondary: {
        text: colors.common.darkgray20,
        textHover: colors.common.darkgray80,
        textDisabled: colors.common.darkgray20,
        background: colors.common.transparent,
        backgroundHover: colors.common.transparent,
        backgroundDisabled: colors.common.transparent,
      },
      icon: {
        text: colors.common.darkgray20,
        textHover: colors.common.darkgray80,
        textDisabled: colors.common.darkgray20,
        background: colors.common.transparent,
        backgroundHover: colors.common.transparent,
        backgroundDisabled: colors.common.transparent,
      },
      subscribe: {
        text: colors.common.odxWhite,
        textHover: colors.common.odxWhite,
        textDisabled: colors.common.darkgray20,
        background: colors.common.red20,
        backgroundHover: colors.common.red40,
        backgroundDisabled: colors.common.lightgray60,
      },
    },
  },
}

export default ODXTheme
