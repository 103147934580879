import * as React from 'react'

function SvgQuestionFill(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.437 12.319l-.48.357c-.416.312-.541.535-.541.758v.506c.013.363-.216.966-.99.966-.773 0-1.063-.5-1.036-.966v-.818c0-.595.263-1.13 1.083-1.695l.54-.372c.682-.461 1.347-.996 1.347-1.74 0-.906-.651-1.486-1.67-1.486-.96 0-1.64.4-1.765 1.442-.046.402-.416.795-.802.795-.96 0-1.161-.735-1.084-1.374.216-1.74 1.7-2.692 3.48-2.692 2.707 0 3.806 1.427 3.806 3.33 0 1.398-.774 2.17-1.888 2.989zm-2.013 5.68a1.2 1.2 0 110-2.399 1.2 1.2 0 010 2.4zm0-16c-5.522 0-10 4.478-10 10 0 5.524 4.478 10 10 10 5.523 0 10-4.476 10-10 0-5.522-4.477-10-10-10z"
      />
    </svg>
  )
}

export default SvgQuestionFill
