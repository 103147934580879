import * as React from 'react'

function SvgSocialYoutube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.15 6.325c-.542-.965-1.131-1.143-2.33-1.21C17.622 5.034 14.611 5 12.002 5c-2.613 0-5.626.034-6.822.114-1.196.069-1.786.245-2.334 1.211C2.288 7.289 2 8.949 2 11.871v.01c0 2.91.288 4.583.846 5.537.548.965 1.136 1.14 2.333 1.22 1.197.07 4.21.112 6.823.112 2.61 0 5.62-.041 6.82-.11 1.198-.081 1.787-.256 2.33-1.221.563-.954.848-2.627.848-5.537V11.873c0-2.924-.285-4.584-.85-5.548zm-11.65 9.3v-7.5l6.25 3.75-6.25 3.75z"
      />
    </svg>
  )
}

export default SvgSocialYoutube
