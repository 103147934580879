import * as React from 'react'

function SvgSocialAppleCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm2.423 6.363c-1.21 0-2.185.732-2.816.732-.66 0-1.545-.692-2.592-.692C7.023 7.403 5 9.06 5 12.161c0 1.941.752 3.984 1.677 5.316l.274.382c.678.929 1.328 1.641 2.207 1.641.986 0 1.423-.65 2.653-.65 1.24 0 1.524.63 2.612.63s1.81-.986 2.49-1.962c.753-1.128 1.078-2.226 1.088-2.277l-.079-.034c-.389-.178-2.055-1.076-2.055-3.209 0-2.053 1.626-2.968 1.717-3.04-1.067-1.555-2.703-1.595-3.16-1.595zm.244-3.863c-.793.03-1.769.529-2.348 1.21-.447.518-.874 1.341-.874 2.165 0 .122.02.254.03.295.051.01.132.02.214.02.721 0 1.636-.488 2.175-1.139.488-.6.834-1.413.834-2.236 0-.112-.01-.224-.03-.315z"
      />
    </svg>
  )
}

export default SvgSocialAppleCircle
