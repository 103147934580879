import React, { ReactNode, ChangeEvent } from 'react'
import styled from '@emotion/styled'

import { body3 } from '../styles/typography'
import { focusRing, screenReaderText, inputReset } from '../styles/mixin'

import Icon from './Icon'

interface Props {
  className?: string
  label: string | ReactNode
  name?: string
  checked?: boolean
  disabled?: boolean
  error?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  testId?: string
}

const Label = styled.label<Pick<Props, 'disabled'>>`
  ${body3}
  display: inline-flex;
  align-items: flex-start;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const Div = styled.div<Pick<Props, 'error' | 'disabled' | 'checked'>>`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin: 2.5px 8px 2.5px 0;
  border: 1px solid ${({ theme }) => theme.colors.lightgray60};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.white100};

  ${({ checked, theme }) =>
    checked &&
    `
      border-color: ${theme.colors.secondary};
      background-color: ${theme.colors.white100};
      color: ${theme.colors.secondary};
    `}

  ${({ disabled, theme }) =>
    disabled &&
    `
      border-color: ${theme.colors.lightgray80};
      background-color: ${theme.colors.lightgray20};
      color: ${theme.colors.darkgray20};
      opacity: ${theme.opacity.disabled};
    `}

  ${({ error, theme }) =>
    error &&
    `
      border-color: ${theme.colors.red20};
      background-color:  ${theme.colors.errorBackground};
      color: ${theme.colors.red20};
    `}

`
const Input = styled.input`
  /* TODO: 서비스에 디자인시스템 모두 적용되면, inputReset CSSReset으로 옮기기 & 스냅샷 업데이트 */
  ${inputReset}
  ${screenReaderText}

  &:focus + div {
    ${focusRing}
    border-color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.white100};
  }
`

const Span = styled.span<Pick<Props, 'disabled'>>`
  width: calc(100% - 16px);
  color: ${({ theme }) => theme.colors.darkgray20};
  opacity: ${({ theme, disabled }) => (disabled ? theme.opacity.disabled : 1)};
`

export default function Checkbox({
  className,
  label,
  name,
  checked = false,
  disabled = false,
  error = false,
  onChange,
  onBlur,
  testId,
}: Props) {
  return (
    <Label className={className} disabled={disabled}>
      <Input
        type="checkbox"
        name={name}
        disabled={disabled}
        aria-checked={checked}
        aria-invalid={error}
        checked={checked}
        onBlur={onBlur}
        onChange={onChange}
        data-testid={testId}
      />
      <Div checked={checked} disabled={disabled} error={error}>
        {checked && <Icon name="Check" size="small" />}
      </Div>
      <Span disabled={disabled}>{label}</Span>
    </Label>
  )
}
