import * as React from 'react'

function SvgWatchLine(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 24" fill="currentColor" {...props}>
      <path d="M13.424 7v4.586l2.807 2.807a.999.999 0 11-1.414 1.414l-3.1-3.1a.997.997 0 01-.293-.707V7a1 1 0 012 0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.42 2l.22.002c5.521.119 9.9 4.692 9.782 10.214C22.305 17.664 17.85 22 12.428 22l-.216-.002h-.003c-5.523-.12-9.902-4.692-9.783-10.214C2.543 6.336 6.997 2 12.42 2zm-.168 17.998l.176.002c4.314 0 7.901-3.511 7.994-7.828a7.95 7.95 0 00-2.22-5.706 7.948 7.948 0 00-5.606-2.464L12.421 4c-4.316 0-7.903 3.511-7.995 7.828-.096 4.41 3.415 8.075 7.825 8.17z"
      />
    </svg>
  )
}

export default SvgWatchLine
