import React from 'react'
import styled from '@emotion/styled'
import classNames from 'classnames'

import Icon, { IconType } from './Icon'
import { body2 } from '../styles/typography'

interface Props {
  className?: string
  variant: 'informative' | 'positive' | 'negative' | 'alert'
  icon?: IconType
  text: string
  testId?: string
}

const Div = styled.div<Pick<Props, 'variant'>>`
  ${body2}
  display: flex;
  padding: 16px;
  border-radius: 6px;

  ${({ theme, variant }) =>
    variant === 'informative' &&
    `
    border: 1px solid transparent;
    background-color: ${theme.colors.blue20};
    color: ${theme.colors.odxWhite};
  `}

  ${({ theme, variant }) =>
    variant === 'positive' &&
    `
    border: 1px solid transparent;
    background-color: ${theme.colors.green20};
    color: ${theme.colors.odxWhite};
  `}

  ${({ theme, variant }) =>
    variant === 'negative' &&
    `
    border: 1px solid transparent;
    background-color: ${theme.colors.red40};
    color: ${theme.colors.odxWhite};
  `}

  ${({ theme, variant }) =>
    variant === 'alert' &&
    `
    border: 1px solid ${theme.colors.red20};
    background-color: ${theme.colors.errorBackground};
    color: ${theme.colors.red20};
  `}
`

const P = styled.p<{ hasIcon: boolean }>`
  display: inline-block;
  ${({ hasIcon }) => hasIcon && 'margin-left: 8px;'}
`

export default function InlineAlert({ variant = 'alert', className, icon, text, testId }: Props) {
  return (
    <Div className={classNames('InlineAlert', className)} variant={variant} role="alert" data-testid={testId}>
      {!!icon && <Icon name={icon} size="large" />}
      <P hasIcon={!!icon}>{text}</P>
    </Div>
  )
}
