import * as React from 'react'

function SvgSocialInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.002 6.871a5.119 5.119 0 00-5.126 5.127 5.118 5.118 0 005.126 5.126 5.118 5.118 0 005.127-5.126 5.119 5.119 0 00-5.127-5.127zm0 8.46a3.34 3.34 0 01-3.333-3.333 3.336 3.336 0 013.333-3.333 3.336 3.336 0 013.333 3.333 3.34 3.34 0 01-3.333 3.333zm6.532-8.67c0 .665-.535 1.196-1.195 1.196a1.196 1.196 0 111.196-1.196zm3.396 1.214c-.076-1.602-.442-3.02-1.616-4.19-1.169-1.169-2.587-1.534-4.19-1.615-1.65-.093-6.598-.093-8.249 0-1.597.076-3.016.442-4.19 1.611C2.512 4.85 2.151 6.269 2.07 7.871c-.093 1.65-.093 6.598 0 8.25.076 1.601.442 3.02 1.615 4.189 1.174 1.169 2.588 1.535 4.19 1.615 1.65.094 6.599.094 8.25 0 1.602-.076 3.02-.442 4.19-1.615 1.168-1.17 1.534-2.588 1.615-4.19.093-1.65.093-6.594 0-8.245zm-2.133 10.017a3.374 3.374 0 01-1.9 1.9c-1.317.523-4.44.402-5.895.402-1.454 0-4.582.116-5.894-.402a3.374 3.374 0 01-1.9-1.9c-.523-1.316-.402-4.44-.402-5.894 0-1.455-.116-4.583.401-5.894a3.374 3.374 0 011.901-1.901c1.316-.522 4.44-.402 5.894-.402 1.455 0 4.582-.116 5.894.402a3.374 3.374 0 011.9 1.9c.523 1.317.402 4.44.402 5.895 0 1.454.12 4.582-.401 5.894z"
      />
    </svg>
  )
}

export default SvgSocialInstagram
