import * as React from 'react'

function SvgDeleteForever(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm3.168-6.415a.997.997 0 011.407-1.413L12 12.59l1.415-1.415a.997.997 0 111.41 1.41L13.41 14l1.415 1.415a.997.997 0 11-1.41 1.41L12 15.41l-1.415 1.415a.997.997 0 11-1.41-1.41L10.59 14l-1.422-1.415zM15.5 4l-1-1h-5l-1 1H5v2h14V4h-3.5z"
      />
    </svg>
  )
}

export default SvgDeleteForever
