import * as React from 'react'

function SvgInfoLine(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M12 6a1 1 0 100 2 1 1 0 000-2zm-1 4a1 1 0 112 0v7a1 1 0 11-2 0v-7z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.478 2 2 6.477 2 12s4.478 10 10 10 10-4.477 10-10S17.522 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
      />
    </svg>
  )
}

export default SvgInfoLine
