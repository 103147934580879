import React, { useState, useRef, useEffect, memo, ReactNode } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { get, isNull, findIndex, isEmpty, isNil } from 'lodash'

import useEventListener from '../hooks/useEventListener'
import useId from '../hooks/useId'
import { isKeyboardEvent } from '../utils/eventUtils'
import { KeyCode } from '../constants/enum'

import { focusRing, screenReaderText, truncatedText } from '../styles/mixin'
import { body2, body3 } from '../styles/typography'

import Icon, { IconType } from './Icon'
import Text from './Text'

type Size = 'small' | 'large'
type DropdownPosition = 'up' | 'down'

const DISABLED_BUTTON_PLACEHOLDER = 'No option' // TODO: fallback placeholder 디자인 텍스트로 교체
const SPACE_BETWEEN_BUTTON_LIST = 4
const LABEL_HEIGHT = 29
const LIST_ITEM_HEIGHT = 40
const LIST_BOX_TOP_BOTTOM_PADDING = 4
const LIST_BOX_MAX_HEIGHT = LIST_BOX_TOP_BOTTOM_PADDING + LIST_ITEM_HEIGHT * 4 + LIST_ITEM_HEIGHT / 2
const DEFAULT_POSITION = 'down'

const buttonHeight = {
  small: 40,
  large: 48,
}

const getListBoxPosition = ({ size, hideLabel }: { size: Size; hideLabel?: boolean }) =>
  hideLabel
    ? buttonHeight[size] + SPACE_BETWEEN_BUTTON_LIST
    : buttonHeight[size] + SPACE_BETWEEN_BUTTON_LIST + LABEL_HEIGHT

const getOptionProperty = (options: Option[], idx: number, property: 'label' | 'value') =>
  get(options[idx], property, '')

interface Option {
  label: string
  value: string | number
}

interface Props {
  label: string
  options: Option[]
  size?: Size
  position?: DropdownPosition
  className?: string
  hideLabel?: boolean
  assistiveText?: string
  assistiveEl?: ReactNode
  fullWidth?: boolean
  iconType?: IconType
  defaultOption?: number | string
  placeholder?: string
  error?: boolean
  errorMessage?: string
  testId?: string
  onSelect: (value: string) => void
  onClick?: () => void
  onBlur?: () => void
}

const Li = styled.li<{ isActive: boolean; isFocused: boolean; size: Size }>`
  ${truncatedText}
  display: block;
  width: 100%;
  height: ${`${LIST_ITEM_HEIGHT}px`};
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.white100};
  color: ${({ theme }) => theme.colors.darkgray20};
  text-align: left;
  cursor: pointer;

  ${({ size }) => (size === 'large' ? body2 : body3)}

  ${({ isActive, theme }) =>
    isActive &&
    `
    background-color: ${theme.colors.lightgray80};
    color: ${theme.colors.odxWhite};
    `}

  ${({ isFocused, theme }) =>
    isFocused &&
    `
    background-color: ${theme.colors.lightgray40};
    color: ${theme.colors.darkgray20};
    `}
`

const Ul = styled.ul<{ hideLabel: boolean; isOpen: boolean; position: DropdownPosition; size: Size }>`
  position: absolute;
  top: ${({ position, size, hideLabel }) =>
    position === DEFAULT_POSITION ? getListBoxPosition({ size, hideLabel }) + 'px' : 'initial'};
  bottom: ${({ position, size, hideLabel }) =>
    position === DEFAULT_POSITION ? 'initial' : getListBoxPosition({ size, hideLabel }) + 'px'};
  z-index: 1;
  display: ${({ isOpen }) => (isOpen ? 'initial' : 'none')};
  overflow: auto;
  width: 100%;
  max-height: ${`${LIST_BOX_MAX_HEIGHT}px`};
  padding-top: ${`${LIST_BOX_TOP_BOTTOM_PADDING}px`};
  padding-bottom: ${`${LIST_BOX_TOP_BOTTOM_PADDING}px`};
  border: 1px solid ${({ theme }) => theme.colors.lightgray60};
  border-radius: 6px;
  background-color: white;
  color: ${({ theme }) => theme.colors.darkgray20};

  &:focus {
    ${focusRing}
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const P = styled.p<{ isOpen: boolean }>`
  ${body3}
  color: ${({ theme }) => theme.colors.red20};
  line-height: 1.25rem;
  text-align: left;

  ${({ isOpen }) => isOpen && 'visibility: hidden;'}
`

const OptionIcon = styled(Icon)`
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.lightgray80};
`

const ArrowIcon = styled(Icon)<{ isOpen: boolean }>`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.darkgray20};
  transition: all ease 0.5s;

  ${({ isOpen }) => isOpen && 'transform: rotate(-180deg);'}
`

const StyledButton = styled.button<{ error: boolean; isSelectedOption: boolean; size: Size }>`
  & > * {
    pointer-events: none;
  }

  ${({ theme, error, isSelectedOption, size = 'large' }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: ${`${buttonHeight[size]}px`};
    padding: 12px 8px 12px 16px;
    border: 1px solid ${theme.colors.lightgray60};
    border-radius: 6px;
    background-color: ${theme.colors.white100};
    color: ${isSelectedOption ? theme.colors.darkgray80 : theme.colors.darkgray20};
    line-height: 24px;
    cursor: pointer;

    &:focus {
      border-color: ${error ? theme.colors.red20 : theme.colors.secondary};
      background-color: ${error ? theme.colors.errorBackground : theme.colors.white100};
      color: ${isSelectedOption ? theme.colors.darkgray80 : theme.colors.darkgray20};
    }

    &:disabled {
      border-color: ${theme.colors.lightgray80};
      background-color: ${theme.colors.lightgray20};
      color: ${theme.colors.darkgray20};
      opacity: ${theme.opacity.disabled};
      cursor: not-allowed;
    }

    ${error &&
    `
      border-color: ${theme.colors.red20};
      background-color: ${theme.colors.errorBackground};
      color: ${isSelectedOption ? theme.colors.darkgray80 : theme.colors.darkgray80};
      `}
  `}
`

const OptionText = styled(Text)`
  width: 100%;
  text-align: left;
`

const AssistiveText = styled(Text)`
  height: 29px;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.darkgray20};
`

const AssistiveElWrapper = styled.div`
  height: 29px;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.lightgray80};
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const Label = styled.label<{ hideLabel?: boolean }>`
  ${body3}
  height: 29px;
  color: ${({ theme }) => theme.colors.darkgray80};

  ${({ hideLabel }) => hideLabel && screenReaderText}
`

const Wrapper = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  display: inline-block;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`

function Dropdown({
  label,
  position = 'down',
  size = 'large',
  options,
  className,
  hideLabel = false,
  assistiveEl,
  assistiveText,
  fullWidth,
  iconType,
  defaultOption,
  placeholder,
  error,
  errorMessage,
  testId,
  onSelect,
  onClick,
  onBlur,
}: Props) {
  const dropdownId = useId('Dropdown')
  const defaultOptionIndex = findIndex(options, ['value', defaultOption])
  const initialOptionId = defaultOptionIndex >= 0 ? defaultOptionIndex : null
  const disabled = isNil(options) || isEmpty(options)

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptionId, setSelectedOptionId] = useState(initialOptionId)
  const [focusedOptionId, setFocusedOptionId] = useState(selectedOptionId || 0)

  const $buttonRef = useRef<HTMLButtonElement>(null)
  const $listboxRef = useRef<HTMLUListElement>(null)
  const $focusedListItemEl = $listboxRef.current?.children[focusedOptionId] || null

  // NOTE: When the dropdown opens, focus on the listbox
  useEffect(() => {
    if (isOpen) {
      $listboxRef.current?.focus()
    }
  }, [isOpen])

  useEffect(() => {
    setSelectedOptionId(initialOptionId)
  }, [options])

  // NOTE: Scroll to focused option
  useEffect(() => {
    $focusedListItemEl?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }, [focusedOptionId])

  const firstOptionId = 0
  const lastOptionId = options.length - 1

  const toggle = (e: React.MouseEvent | React.KeyboardEvent) => {
    if (isKeyboardEvent(e)) {
      if (e.keyCode === KeyCode.ARROW_UP || e.keyCode === KeyCode.ARROW_DOWN || e.keyCode === KeyCode.ENTER) {
        e.preventDefault()
        setIsOpen(!isOpen)
        onClick?.()
      }
    } else {
      setIsOpen(!isOpen)
      onClick?.()
    }
  }
  const close = () => setIsOpen(false)
  const focusUp = () => {
    if (focusedOptionId !== firstOptionId) {
      setFocusedOptionId(focusedOptionId - 1)
    }
  }
  const focusDown = () => {
    if (focusedOptionId !== lastOptionId) {
      setFocusedOptionId(focusedOptionId + 1)
    }
  }

  const getButtonText = () => {
    if (disabled) {
      return DISABLED_BUTTON_PLACEHOLDER
    }

    if (isNull(selectedOptionId)) {
      return placeholder ?? getOptionProperty(options, firstOptionId, 'label')
    }

    return getOptionProperty(options, selectedOptionId, 'label')
  }

  const handleSelect = (id: number) => {
    $buttonRef.current?.focus()
    setSelectedOptionId(id)
    onSelect(`${getOptionProperty(options, id, 'value')}`)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.keyCode) {
      case KeyCode.ARROW_UP: {
        e.preventDefault()
        focusUp()
        break
      }
      case KeyCode.ARROW_DOWN: {
        e.preventDefault()
        focusDown()
        break
      }
      case KeyCode.ENTER:
      case KeyCode.SPACEBAR: {
        e.preventDefault()
        handleSelect(focusedOptionId)
        close()
        break
      }
      case KeyCode.TAB: {
        close()
        break
      }
      case KeyCode.ESC: {
        close()
        $buttonRef.current?.focus()
        break
      }
    }
  }

  // NOTE: Close the Dropdown when click outside
  useEventListener([
    {
      eventName: 'click',
      callback: e => {
        const $buttonEl = $buttonRef.current
        if (e.target !== $buttonEl) {
          close()
        }
      },
    },
  ])

  return (
    <Wrapper className={className} fullWidth={fullWidth} data-testid={testId}>
      <LabelWrapper>
        <Label hideLabel={hideLabel} htmlFor={`${dropdownId}__button`} id={`${dropdownId}__label`}>
          {label}
        </Label>
        {assistiveEl && <AssistiveElWrapper>{assistiveEl}</AssistiveElWrapper>}
        {assistiveText && (
          <AssistiveText size="body4" isTruncated>
            {assistiveText}
          </AssistiveText>
        )}
      </LabelWrapper>
      <StyledButton
        type="button"
        size={size}
        error={!!error && !isOpen}
        isSelectedOption={!isNull(selectedOptionId)}
        ref={$buttonRef}
        id={`${dropdownId}__button`}
        onClick={toggle}
        onKeyDown={toggle}
        onBlur={onBlur}
        aria-labelledby={`${dropdownId}__label ${dropdownId}__button`}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        disabled={disabled}
      >
        {iconType && <OptionIcon name={iconType} />}
        <OptionText as="span" size={size === 'large' ? 'body2' : 'body3'} isTruncated>
          {getButtonText()}
        </OptionText>
        <ArrowIcon name={position === DEFAULT_POSITION ? 'ArrowMiniDown' : 'ArrowMiniUp'} isOpen={isOpen} />
      </StyledButton>
      {error && errorMessage && <P isOpen={isOpen}>{errorMessage}</P>}
      <Ul
        hideLabel={hideLabel}
        size={size}
        isOpen={isOpen}
        position={position}
        ref={$listboxRef}
        id={`${dropdownId}__dropdownList`}
        role="listbox"
        tabIndex={-1}
        onKeyDown={handleKeyDown}
        aria-labelledby={`${dropdownId}__label`}
        aria-activedescendant={`${dropdownId}__${getOptionProperty(options, focusedOptionId, 'label')}`}
      >
        {options.map(({ label }, idx) => {
          return (
            <Li
              key={label}
              size={size}
              isActive={selectedOptionId === idx}
              isFocused={focusedOptionId === idx}
              id={`${dropdownId}__${label}`}
              title={label}
              role="option"
              onClick={() => {
                handleSelect(idx)
                close()
              }}
              onMouseEnter={() => setFocusedOptionId(idx)}
              onMouseLeave={() => setFocusedOptionId(-1)}
              aria-selected={selectedOptionId === idx}
            >
              {label}
            </Li>
          )
        })}
      </Ul>
    </Wrapper>
  )
}

export default memo(Dropdown)
