import * as React from 'react'

function SvgQuestionLine(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 24" fill="currentColor" {...props}>
      <path d="M12.519 6c-1.78 0-3.264.952-3.48 2.691-.077.64.124 1.375 1.084 1.375.386 0 .756-.394.802-.795.125-1.041.805-1.442 1.765-1.442 1.019 0 1.67.58 1.67 1.487 0 .743-.665 1.278-1.346 1.739l-.541.372c-.82.565-1.083 1.1-1.083 1.695v.817c-.027.467.263.967 1.036.967.774 0 1.003-.603.99-.967v-.505c0-.223.124-.446.54-.758l.48-.357c1.115-.818 1.889-1.591 1.889-2.988 0-1.904-1.1-3.331-3.806-3.331zm-1.295 10.8a1.2 1.2 0 112.4 0 1.2 1.2 0 01-2.4 0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.424 2c-5.522 0-10 4.477-10 10s4.478 10 10 10 10-4.477 10-10-4.478-10-10-10zm0 18c-4.41 0-8-3.589-8-8s3.59-8 8-8c4.411 0 8 3.589 8 8s-3.589 8-8 8z"
      />
    </svg>
  )
}

export default SvgQuestionLine
