import * as React from 'react'

function SvgSocialTwitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 6.13a8.192 8.192 0 01-2.357.647 4.11 4.11 0 001.805-2.27 8.22 8.22 0 01-2.606.996 4.096 4.096 0 00-2.995-1.296c-2.65 0-4.596 2.471-3.998 5.037a11.648 11.648 0 01-8.457-4.287 4.109 4.109 0 001.27 5.479 4.085 4.085 0 01-1.858-.514c-.045 1.901 1.318 3.68 3.291 4.075a4.113 4.113 0 01-1.853.07 4.106 4.106 0 003.833 2.85A8.25 8.25 0 012 18.617a11.616 11.616 0 006.29 1.843c7.618 0 11.922-6.434 11.663-12.205A8.353 8.353 0 0022 6.131z"
      />
    </svg>
  )
}

export default SvgSocialTwitter
