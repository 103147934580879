const common = {
  transparent: 'transparent',
  odxWhite: '#FBFBFB',
  odxBlack: '#0C0C0D',
  // Gray
  lightgray20: '#F3F4F4',
  lightgray40: '#E4EBEE',
  lightgray60: '#C8D4DB',
  lightgray80: '#A7B9C4',
  darkgray20: '#627280',
  darkgray40: '#35414E',
  darkgray60: '#222A36',
  darkgray80: '#141A23',
  // White
  white20: 'rgba(255,255,255,0.2)',
  white40: 'rgba(255,255,255,0.4)',
  white60: 'rgba(255,255,255,0.6)',
  white80: 'rgba(255,255,255,0.8)',
  white100: 'rgba(255,255,255,1)',
  // Black
  black20: 'rgba(0,0,0,0.2)',
  black40: 'rgba(0,0,0,0.4)',
  black60: 'rgba(0,0,0,0.6)',
  black80: 'rgba(0,0,0,0.8)',
  black100: 'rgba(0,0,0,1)',
  // Blue
  blue20: '#4A90E2',
  // Red
  red20: '#FF5D5D',
  red40: '#E64646',
  errorBackground: 'rgba(255,93,93,0.1)',
  // Green
  green20: '#0EB151',
}

const odk = {
  green20: '#0EB151',
  green40: '#09873D',
  blue20: '#3263FA',
  blue40: '#2549B8',
}

const odc = {
  pink: '#E61576',
  pink20: '#F15C62',
  pink40: '#A72A35',
  purple20: '#8E5585',
  purple40: '#5F3252',
}

const odl = {
  orange: '#FCB017',
  orange20: '#FCB017',
  orange40: '#F26822',
  secOrange20: '#FCB017',
  secOrange40: '#F26822',
}

const colors = {
  common,
  odk,
  odc,
  odl,
}

export default colors
