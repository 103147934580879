import React, { ChangeEvent, forwardRef, ReactText } from 'react'
import styled from '@emotion/styled'

import useId from '../hooks/useId'

import { focusRing, screenReaderText } from '../styles/mixin'
import { body2, body3 } from '../styles/typography'

import Text from './Text'

interface Props {
  className?: string
  label: string
  placeholder?: string
  name?: string
  value?: ReactText
  rows?: number
  cols?: number
  maxLength?: number
  minLength?: number
  hideLabel?: boolean
  error?: boolean
  errorMessage?: string
  optionalText?: string
  disabled?: boolean
  resize?: boolean
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: () => void
  testId?: string
  readOnly?: boolean
  required?: boolean
}

const LABEL_LINE_HEIGHT = '20px'
const LABEL_MARGIN_BOTTOM = '8px'
const TEXT_AREA_HEIGHT = '72px'
const ERROR_MESSAGE_LINE_HEIGHT = '20px'

const Div = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(${LABEL_LINE_HEIGHT} + ${LABEL_MARGIN_BOTTOM} + ${TEXT_AREA_HEIGHT} + ${ERROR_MESSAGE_LINE_HEIGHT});
`

const Label = styled.label<{ hideLabel: boolean }>`
  ${body3}
  ${({ hideLabel }) => hideLabel && screenReaderText}

  margin-bottom: ${LABEL_MARGIN_BOTTOM};
  color: ${({ theme }) => theme.colors.darkgray80};
  line-height: ${LABEL_LINE_HEIGHT};
`

const StyledText = styled(Text)`
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.darkgray20};
`

const StyledTextArea = styled.textarea<Pick<Props, 'resize' | 'error'>>`
  ${body2}
  width: 100%;
  min-height: ${TEXT_AREA_HEIGHT};
  padding: 12px 16px;
  border: 1px solid ${({ theme }) => theme.colors.lightgray60};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.darkgray80};
  resize: ${({ resize }) => (resize ? 'vertical' : 'none')};

  &::placeholder {
    color: ${({ theme }) => theme.colors.darkgray20};
  }

  &:focus {
    ${focusRing}
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  &:disabled {
    border-color: ${({ theme }) => theme.colors.lightgray80};
    background: ${({ theme }) => theme.colors.lightgray20};
    color: ${({ theme }) => theme.colors.darkgray20};
    opacity: ${({ theme }) => theme.opacity.disabled};

    cursor: not-allowed;
  }

  ${({ error, theme }) =>
    error &&
    `
      border-color: ${theme.colors.red20};
      background-color:  ${theme.colors.errorBackground};
    `}
`

const P = styled.span`
  ${body3}
  width: 100%;
  color: ${({ theme }) => theme.colors.red20};
  line-height: ${ERROR_MESSAGE_LINE_HEIGHT};
`

const TextArea = forwardRef(
  (
    {
      className,
      label,
      hideLabel = false,
      value,
      placeholder,
      maxLength,
      minLength,
      error,
      errorMessage,
      disabled,
      resize,
      name,
      optionalText,
      cols,
      rows,
      required,
      onChange,
      onBlur,
      testId,
      readOnly,
    }: Props,
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    const id = useId('Textarea')

    return (
      <Div className={className}>
        <Label hideLabel={hideLabel} htmlFor={id}>
          {label}
          {!required && optionalText && (
            <StyledText as="span" size="body4">
              {optionalText}
            </StyledText>
          )}
        </Label>
        <StyledTextArea
          ref={ref}
          id={id}
          name={name}
          placeholder={placeholder}
          cols={cols}
          rows={rows}
          value={value}
          maxLength={maxLength}
          minLength={minLength}
          disabled={disabled}
          error={error}
          aria-invalid={error}
          resize={resize}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
          data-testid={testId}
          readOnly={readOnly}
        />
        {error && errorMessage && <P aria-live="polite">{errorMessage}</P>}
      </Div>
    )
  }
)

export default TextArea
