import * as React from 'react'

function SvgPasswordHidden(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.43 7.502a5.004 5.004 0 015.001 5.002c0 .65-.13 1.26-.36 1.83l2.921 2.922a11.824 11.824 0 003.432-4.752c-1.73-4.392-6.003-7.503-11.005-7.503-1.4 0-2.741.25-3.982.7l2.161 2.161c.57-.23 1.18-.36 1.831-.36zM2.424 4.77l2.74 2.74a11.81 11.81 0 00-3.74 4.993c1.73 4.392 6.002 7.504 11.004 7.504 1.55 0 3.031-.3 4.382-.84l.42.42 2.932 2.92 1.27-1.27L3.695 3.5l-1.27 1.27zm5.532 5.532l1.55 1.55c-.05.21-.08.431-.08.651a2.999 2.999 0 003.002 3.002c.22 0 .44-.03.65-.08l1.551 1.55c-.67.33-1.41.53-2.2.53a5.004 5.004 0 01-5.003-5.002c0-.79.2-1.53.53-2.2zm4.312-.78l3.151 3.151.02-.16c0-1.66-1.34-3.001-3-3.001l-.171.01z"
      />
    </svg>
  )
}

export default SvgPasswordHidden
