import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

import { buttonTextLarge, buttonTextSmall } from '../styles/typography'

import Icon, { IconType } from './Icon'
import Loading from './Loading'

export type ButtonType = 'button' | 'submit' | 'reset'
export type ButtonVariant = 'primary' | 'secondary' | 'error' | 'icon' | 'subscribe' | 'ghostPrimary' | 'ghostSecondary'
export type ButtonSize = 'small' | 'medium' | 'large'
export type IconPosition = 'left' | 'right'

interface Props {
  className?: string
  type?: ButtonType

  title?: string
  variant?: ButtonVariant
  size?: ButtonSize
  fullWidth?: boolean
  /**
   * Button이 텍스트처럼 쓰여져야 할 경우,
   * noPadding 옵션을 주면 padding이 제거되고 `height: auto`로 설정되어 함께 있는 텍스트의 플로우에 맞춰집니다.
   */
  noPadding?: boolean

  icon?: IconType
  iconPosition?: IconPosition

  isLoading?: boolean
  disabled?: boolean

  onClick?: () => void
  children?: ReactNode

  testId?: string
}

const buttonHeight = {
  small: '32px',
  medium: '40px',
  large: '48px',
}

type ButtonProps = Required<Pick<Props, 'variant' | 'size'>> &
  Pick<Props, 'fullWidth' | 'iconPosition' | 'noPadding' | 'isLoading' | 'disabled'>

export const StyledButton = styled('button', {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})<ButtonProps>`
  ${({ theme, variant, size, fullWidth, iconPosition, noPadding, isLoading, disabled }) => {
    const isBorder = variant === 'secondary' || variant === 'error'
    return `
    display: inline-flex;
    flex-direction: ${iconPosition === 'left' ? 'row' : 'row-reverse'};
    justify-content: center;
    align-items: center;
    width: ${fullWidth ? '100%' : 'auto'};
    color: ${theme.button[variant].text};
    height: ${noPadding ? 'auto' : buttonHeight[size]};
    border-radius: ${variant === 'ghostPrimary' || variant === 'icon' ? 0 : '6px'};
    padding: ${noPadding ? 0 : variant === 'icon' ? '12px' : '5px 12px'};
    background-color: ${theme.button[variant].background};
    border-style: solid;
    border-color: ${isBorder ? theme.button[variant].border : 'transparent'};
    border-width: ${isBorder ? '1px' : 0};
    cursor: ${isLoading ? 'default' : disabled ? 'not-allowed' : 'pointer'};

    &:hover {
      ${
        !isLoading &&
        !disabled &&
        `
        background-color: ${theme.button[variant].backgroundHover};
        color: ${theme.button[variant].textHover};
        border-color: ${isBorder ? theme.button[variant].borderHover : 'transparent'};
        text-decoration: ${theme.name === 'odc' && variant === 'ghostPrimary' ? 'underline' : 'none'};
      `
      }
    }

    &:disabled {
      color: ${theme.button[variant].textDisabled};
      background-color: ${theme.button[variant].backgroundDisabled};
      border-color: ${isBorder ? theme.button[variant].borderDisabled : 'transparent'};
      opacity: ${theme.opacity.disabled};
    }
  `
  }}
`

export const Span = styled.span<Pick<Props, 'size'>>`
  width: 100%;
  margin-top: -1px;
  white-space: nowrap;
  ${({ size }) => (size === 'small' ? buttonTextSmall : buttonTextLarge)}
`

export const StyledIcon = styled(Icon)<Pick<Props, 'variant' | 'iconPosition'>>`
  margin-right: ${({ variant, iconPosition }) => (variant !== 'icon' && iconPosition === 'left' ? '4px' : 0)};
  margin-left: ${({ variant, iconPosition }) => (variant !== 'icon' && iconPosition === 'right' ? '4px' : 0)};
`

export default function Button({
  className,
  type = 'button',
  title,
  variant = 'primary',
  size = 'large',
  fullWidth,
  noPadding = false,
  icon,
  iconPosition = 'left',
  isLoading = false,
  disabled,
  onClick,
  children,
  testId,
}: Props) {
  return (
    <StyledButton
      className={className}
      type={type}
      onClick={onClick}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      noPadding={noPadding}
      iconPosition={iconPosition}
      isLoading={isLoading}
      disabled={disabled}
      data-testid={testId}
    >
      <Loading isVisible={isLoading} size={size === 'small' ? 'medium' : 'large'} />
      {!isLoading && (
        <>
          {icon && (
            <StyledIcon
              name={icon}
              title={variant === 'icon' ? title || icon : undefined}
              size={size === 'small' ? 'medium' : 'large'}
              variant={variant}
              iconPosition={iconPosition}
            />
          )}
          {variant !== 'icon' && <Span size={size}>{children || title}</Span>}
        </>
      )}
    </StyledButton>
  )
}
