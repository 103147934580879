import * as React from 'react'

function SvgLoader(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.133 2.501a1.667 1.667 0 11-1.044 3.166A6.667 6.667 0 1018.666 12 1.667 1.667 0 1122 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2c1.075 0 2.13.17 3.133.501z"
      />
    </svg>
  )
}

export default SvgLoader
