import * as React from 'react'

function SvgSocialGoogleplus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.833 11v2h3.309c-.134.857-1 2.517-3.309 2.517-1.991 0-3.616-1.65-3.616-3.684 0-2.033 1.625-3.683 3.616-3.683 1.134 0 1.892.483 2.325.9l1.584-1.525C10.725 6.575 9.408 6 7.833 6A5.83 5.83 0 002 11.833a5.83 5.83 0 005.833 5.834c3.367 0 5.601-2.367 5.601-5.7a5.36 5.36 0 00-.092-.967H7.833zM22 12.667h-2.5v2.5h-1.667v-2.5h-2.5V11h2.5V8.5H19.5V11H22v1.667z"
      />
    </svg>
  )
}

export default SvgSocialGoogleplus
