import * as React from 'react'

function SvgSocialWeibo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.899 9.686c.296-.937-.524-1.828-1.461-1.629-.86.188-1.125-1.097-.278-1.281 1.957-.426 3.606 1.45 2.989 3.313-.266.828-1.516.421-1.25-.403zM10.39 20.198C6.238 20.198 2 18.19 2 14.874c0-1.73 1.094-3.727 2.98-5.613 3.895-3.895 7.938-3.942 6.782-.223-.156.512.48.223.48.234 3.106-1.312 5.489-.656 4.453 2.008-.144.367.043.426.325.512 5.3 1.652 1.36 8.406-6.63 8.406zm5.613-5.715c-.21-2.176-3.066-3.672-6.383-3.348-3.312.336-5.812 2.356-5.601 4.532.21 2.176 3.066 3.672 6.383 3.348 3.312-.337 5.812-2.356 5.601-4.532zM15.59 4.12c-1.012.219-.656 1.707.324 1.496 2.824-.594 5.266 2.062 4.364 4.844-.29.945 1.136 1.445 1.46.468 1.247-3.898-2.152-7.652-6.148-6.808zm-3.066 12.148c-.668 1.516-2.61 2.344-4.262 1.809-1.594-.512-2.266-2.086-1.574-3.504.691-1.383 2.464-2.164 4.039-1.762 1.64.422 2.465 1.961 1.797 3.457zm-3.372-1.172c-.503-.21-1.171.012-1.484.504-.324.504-.168 1.094.336 1.329.512.234 1.203.011 1.527-.504.313-.512.145-1.106-.379-1.329zm1.274-.523a.494.494 0 00-.559.21c-.113.2-.054.415.145.505a.48.48 0 00.57-.211c.11-.203.043-.426-.156-.504z"
      />
    </svg>
  )
}

export default SvgSocialWeibo
