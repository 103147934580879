import * as React from 'react'

function SvgLock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 9.125h-.875v-1.75A4.377 4.377 0 0012 3a4.377 4.377 0 00-4.375 4.375v1.75H6.75c-.963 0-1.75.787-1.75 1.75v8.75c0 .962.787 1.75 1.75 1.75h10.5c.962 0 1.75-.788 1.75-1.75v-8.75c0-.963-.788-1.75-1.75-1.75zM12 17c-.963 0-1.75-.788-1.75-1.75 0-.963.787-1.75 1.75-1.75s1.75.787 1.75 1.75c0 .962-.787 1.75-1.75 1.75zm2.713-7.875H9.288v-1.75A2.715 2.715 0 0112 4.662a2.715 2.715 0 012.713 2.713v1.75z"
      />
    </svg>
  )
}

export default SvgLock
