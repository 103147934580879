import * as React from 'react'

function SvgLoad(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M16.56 6.889l1.973-1.973a.495.495 0 01.848.354l.008 5.367c0 .282-.22.502-.502.502h-5.36a.506.506 0 01-.354-.863l1.973-1.973a6.003 6.003 0 00-9.298 1.011c-1.273 1.938-1.266 4.533 0 6.463a6.014 6.014 0 008.654 1.57 1 1 0 011.294.092 1.007 1.007 0 01-.091 1.506 8.013 8.013 0 01-11.49-1.987c-1.776-2.637-1.776-6.187 0-8.825C7.141 3.806 13.066 3.396 16.56 6.89z" />
    </svg>
  )
}

export default SvgLoad
