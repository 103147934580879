import * as React from 'react'

function SvgMenuHamburger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 25" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.106 18.106a1 1 0 001 1h16a1 1 0 100-2h-16a1 1 0 00-1 1zm0-6a1 1 0 001 1h16a1 1 0 100-2h-16a1 1 0 00-1 1zm1-7a1 1 0 100 2h16a1 1 0 100-2h-16z"
      />
    </svg>
  )
}

export default SvgMenuHamburger
