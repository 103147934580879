import * as React from 'react'

function SvgArrowMiniLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 12.5c0-.128.054-.256.163-.354l3.889-3.5a.6.6 0 01.785 0 .465.465 0 010 .708L10.841 12.5l3.496 3.146a.465.465 0 010 .707.6.6 0 01-.785 0l-3.89-3.5A.475.475 0 019.5 12.5z"
      />
    </svg>
  )
}

export default SvgArrowMiniLeft
