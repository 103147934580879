import * as React from 'react'

function SvgArrowTriangleDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.207 11a.5.5 0 00-.353.854l3.792 3.792a.5.5 0 00.708 0l3.793-3.792a.5.5 0 00-.354-.854H8.207z"
      />
    </svg>
  )
}

export default SvgArrowTriangleDown
