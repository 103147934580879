import * as React from 'react'

function SvgArrowMiniRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 12.5a.474.474 0 01-.163.354l-3.889 3.5a.6.6 0 01-.785 0 .465.465 0 010-.708l3.496-3.146-3.496-3.146a.465.465 0 010-.707.6.6 0 01.785 0l3.89 3.5a.474.474 0 01.162.353z"
      />
    </svg>
  )
}

export default SvgArrowMiniRight
