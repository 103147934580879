import * as React from 'react'

function SvgWatchFill(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.231 15.807a.997.997 0 01-1.414 0l-3.1-3.1a.997.997 0 01-.293-.707V7a1 1 0 012 0v4.586l2.807 2.807a.999.999 0 010 1.414zm-3.59-13.804c-5.523-.12-10.096 4.26-10.215 9.782-.119 5.52 4.261 10.094 9.782 10.213 5.522.12 10.094-4.26 10.213-9.782.12-5.522-4.26-10.095-9.78-10.213z"
      />
    </svg>
  )
}

export default SvgWatchFill
