import React from 'react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import Icon, { IconSize } from './Icon'

interface Props {
  className?: string
  /**
   * 로딩 아이콘 사이즈를 나타낸다. small:16px, medium: 20px, large: 24px(디폴트)
   */
  size?: IconSize
  /**
   * 로딩 컴포넌트를 스크린리더에 설명할 타이틀을 제공한다.
   * 디폴드로 'loading' 타이틀이 설정되어 있지만, 더 디테일한 설명이 필요한 경우 title에 추가해주도록 한다.
   */
  title?: string
  /**
   * 로딩 컴포넌트 렌더링 여부를 결정한다.
   */
  isVisible?: boolean
}

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const StyledIcon = styled(Icon)`
  animation: ${spin} 1s ease-in-out infinite;
`

const Loading = ({ className, size = 'large', isVisible = true, title = 'loading' }: Props) => {
  return !isVisible ? null : <StyledIcon className={className} name="Loader" size={size} title={title} />
}

export default Loading
