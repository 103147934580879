import * as React from 'react'

function SvgArrowTriangleRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 16.293a.5.5 0 00.854.353l3.792-3.793a.5.5 0 000-.707l-3.792-3.792a.5.5 0 00-.854.353v7.586z"
      />
    </svg>
  )
}

export default SvgArrowTriangleRight
