import * as React from 'react'

function SvgBack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12a1 1 0 00-1-1H8.434a.25.25 0 01-.177-.427L12.71 6.12a1 1 0 00-1.415-1.415l-6.941 6.941a.5.5 0 000 .708l6.94 6.94a.997.997 0 001.412-1.41l-4.45-4.457A.25.25 0 018.433 13H19a1 1 0 001-1z"
      />
    </svg>
  )
}

export default SvgBack
