import * as React from 'react'

function SvgArrowDefaultDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M2.99 7.869a1.25 1.25 0 000 1.77l8.31 8.31c.39.39 1.02.39 1.41 0l8.31-8.31c.49-.49.49-1.28 0-1.77a1.25 1.25 0 00-1.77 0L12 15.109l-7.25-7.25c-.48-.48-1.28-.48-1.76.01z" />
    </svg>
  )
}

export default SvgArrowDefaultDown
