import * as React from 'react'

function SvgNoImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.759 5.815v.76l-1.667 1.806V6.385c0-.323-.226-.585-.505-.585H5.147c-.279 0-.505.262-.505.585v10.218l2.84-3.753c.356-.468.967-.53 1.389-.14l1.798 1.66 3.87-4.478c.395-.453 1.031-.453 1.425 0l.384.378-7.616 8.248H4.676C3.75 18.518 3 17.706 3 16.704V5.814C3 4.813 3.75 4 4.676 4h13.407c.926 0 1.676.812 1.676 1.815zm-1.676 12.703H12.92l6.838-7.405v5.59c0 1.003-.75 1.815-1.676 1.815zM12.497 8.234c0-1.002-.75-1.814-1.676-1.814-.926 0-1.676.812-1.676 1.814 0 1.003.75 1.815 1.676 1.815.925 0 1.676-.812 1.676-1.815zm10.298-2.616a.782.782 0 00-1.148.002L7.802 20.663a.781.781 0 101.15 1.059L22.797 6.679a.782.782 0 00-.002-1.061z"
      />
    </svg>
  )
}

export default SvgNoImage
