import * as React from 'react'

function SvgSocialFacebookSymbol(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.745 14.874l.438-2.98H13.44v-1.856c0-.782.382-1.546 1.612-1.546H16.3V6.057s-1.133-.193-2.215-.193c-2.26 0-3.737 1.369-3.737 3.85v2.18H7.835v2.86h2.513V22h3.092v-7.246l2.305.12z"
      />
    </svg>
  )
}

export default SvgSocialFacebookSymbol
