import * as React from 'react'

function SvgArrowMiniUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10c.128 0 .256.054.354.163l3.5 3.889a.6.6 0 010 .785.465.465 0 01-.708 0L12 11.341l-3.146 3.496a.465.465 0 01-.707 0 .6.6 0 010-.785l3.5-3.89A.474.474 0 0112 10z"
      />
    </svg>
  )
}

export default SvgArrowMiniUp
