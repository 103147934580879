import { useRef } from 'react'

let id = 0

/**
 *
 * 컴포넌트내에서 동일한 유니크 아이디를 생성
 * 폼에서 라벨과 인풋에 공통된 유니크한 아이디가 필요한 경우 사용할 수 있다.
 * <label htmlFor={id}>label</label>
 * <input id={id} type="text" />
 */

export default function useId(prefix?: string) {
  const ref = useRef(++id)
  return prefix ? `${prefix}-${ref.current}` : `${ref.current}`
}
