import * as React from 'react'

function SvgCastConnected(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.022 19.026v1.92H21.5a1.5 1.5 0 001.5-1.5V4.5A1.5 1.5 0 0021.5 3h-19A1.5 1.5 0 001 4.5V8h1.989V4.994h18.06v14.032h-7.027zM1 21v-3c1.66 0 3 1.34 3 3H1zm0-7v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7zm18-7H5v1.63c3.96 1.28 7.09 4.41 8.37 8.37H19V7zM1 10v2a9 9 0 019 9h2c0-6.08-4.93-11-11-11z"
      />
    </svg>
  )
}

export default SvgCastConnected
