const breakpoints = {
  small: '480px',
  medium: '768px',
  large: '1024px',
  xlarge: '1520px',
}

export const mobile = `@media only screen and (max-width: ${breakpoints.small})`
export const tablet = `@media only screen and (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.medium})`
export const desktop = `@media only screen and (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.large})`
export const desktopL = `@media only screen and (min-width: ${breakpoints.large}) and (max-width: ${breakpoints.xlarge})`
