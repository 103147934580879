import * as React from 'react'

function SvgAccount(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M12 2c5.523 0 10 4.477 10 10a9.962 9.962 0 01-3.212 7.329A9.953 9.953 0 0112 22a9.952 9.952 0 01-6.787-2.671A9.963 9.963 0 012 12C2 6.477 6.477 2 12 2zm0 2c-4.411 0-8 3.589-8 8 0 2.272.957 4.322 2.484 5.779a7.995 7.995 0 0111.033.001A7.975 7.975 0 0020 12c0-4.411-3.588-8-8-8zm0 3.043a3.302 3.302 0 110 6.605 3.302 3.302 0 010-6.605z" />
    </svg>
  )
}

export default SvgAccount
