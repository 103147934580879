import * as React from 'react'

function SvgInfoFill(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8a1 1 0 110-2 1 1 0 010 2zm1 9a1 1 0 11-2 0v-7a1 1 0 112 0v7zM12 2C6.478 2 2 6.477 2 12s4.478 10 10 10 10-4.477 10-10S17.522 2 12 2z"
      />
    </svg>
  )
}

export default SvgInfoFill
