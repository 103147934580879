import * as React from 'react'

function SvgArrowTriangleLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 8.707a.5.5 0 00-.854-.353l-3.792 3.792a.5.5 0 000 .707l3.792 3.793a.5.5 0 00.854-.353V8.707z"
      />
    </svg>
  )
}

export default SvgArrowTriangleLeft
