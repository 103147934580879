import * as React from 'react'

function SvgArrowDefaultUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M21.01 17.131c.49-.49.49-1.28 0-1.77l-8.31-8.31a.996.996 0 00-1.41 0l-8.31 8.31a1.25 1.25 0 000 1.77c.49.49 1.28.49 1.77 0L12 9.891l7.25 7.25c.48.48 1.28.48 1.76-.01z" />
    </svg>
  )
}

export default SvgArrowDefaultUp
