import { css, Theme } from '@emotion/react'

export const screenReaderText = css`
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
`

export const focusRing = ({ theme }: { theme: Theme }) => css`
  outline: 2px solid ${theme.colors.blue20};
`

export const truncatedText = `
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`

// CSS reset mixin
export const inputReset = css`
  margin: 0;
  padding: 0;
  color: inherit;
  line-height: inherit;
`

export const fieldsetReset = css`
  min-width: 0; /* Reset webkit browser UA style sheet (as min-width: -webkit-min-content) */
  min-height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  line-height: inherit;
`

export const legendReset = css`
  padding: 0;
  border: 0;
`
